import data from './pool/data.json';

window.ct = window.ct || {};

ct.shuffleArray = function(array) {
  var result = [], source = array.concat([]);

  while (source.length) {
    let index = Math.floor(Math.random() * source.length);
    result.push(source[index]);
    source.splice(index, 1);
  }

  return result;
}

ct.formatTime = function(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'P.M.' : 'A.M.';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

ct.formatDate = function(date) {
	var day = date.getDate();
  var month = date.getMonth() + 1;
  var year = date.getFullYear();
  var strTime = day + "/" + month + "/" + year;
  return strTime;
}

ct.updateTime = function () {
	var now = new Date();
	ct.formatTime
	var timeEl = document.querySelector('.time');
	var dateEl = document.querySelector('.date');

	timeEl.textContent = ct.formatTime(now);
	dateEl.textContent = ct.formatDate(now);
}

ct.home = function () {
	const date = new Date();
	const hour = date.getHours();
	console.log("hour",hour);

	const targetImage = document.querySelector(`.home-image[data-hour='${hour}']`);

	console.log("targetImage",targetImage)


	if (targetImage) {
		const images = document.querySelectorAll(".home-image");
		images.forEach(img => img.style.display = "none");
		targetImage.style.display = "block";
	} 
}

ct.pool = function () {
	console.log("Data",data);

	ct.poolSteps = data.steps;

	if (data.random) {
		console.log("randomizing");
		ct.poolSteps = ct.shuffleArray(ct.poolSteps);
	}

	ct.nextPoolStep();
},

ct.nextPoolStep = function () {
	const step = ct.poolSteps.shift();

	if (step.indexOf("youtube:") === 0) {
		ct.poolYoutube(step.split("youtube:")[1])
	} else {
		ct.poolImg(step);
	}
	
},

ct.poolImg = function (file) {
	let image = document.createElement("img");
	image.setAttribute("src", `pool/${file}`);
	if (ct.poolSteps.length) {
		image.onclick = ct.nextPoolStep;
	} else {
		image.onclick = function () {
			window.location = "/";
		}
	}
	
	document.querySelector("main").replaceChildren(image);
},

ct.poolYoutube = function (id) {
	var iframe = document.createElement("iframe");
	var iframe_url = "https://www.youtube.com/embed/" + id + "?autoplay=1&autohide=1&mute=0&enablejsapi=1&";
	iframe.setAttribute("src",iframe_url);
	iframe.setAttribute("frameborder",'0');
	iframe.setAttribute("allow", "autoplay; fullscreen");
	console.log("iframe",iframe)
	document.querySelector("main").replaceChildren(iframe);
}

ct.init = function () {
	if (!!document.querySelector("body.homepage")) {
		ct.home();
	}

	if (!!document.querySelector("body.pool")) {
		ct.pool();
	}
}

document.addEventListener("DOMContentLoaded", function(event) { 
	ct.init()
});